export enum CrossroadsReportTypeDto {
  /**
   * Årsoppgave.
   */
  YearlyStatement = 'YEARLY_STATEMENT',
  YearlyCostReport = 'YEARLY_COST_REPORT',
  MonthlyPortfolioReport = 'MONTHLY_PORTFOLIO_REPORT',
  MonthlyAccountReport = 'HOLDING_REPORT',
  DailyTransactionReport = 'TRADE_REPORT',
  Unknown = 'UNKNOWN',
}
