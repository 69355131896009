import Breadcrumbs from '@storeblocks/breadcrumbs';
import React from 'react';

import { QueryState } from '@/api/components/QueryState';
import { useFetchFundHoldingsQuery } from '@/api/fundHoldings/fundHoldingsApi';
import { useFundListQuery } from '@/api/funds/fundsApi';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { isNotReady } from '@/api/utils/isNotReady';
import { AnnouncementWarning } from '@/components/AnnouncementWarning';
import { CenteredPageContent } from '@/components/CenteredPageContent';
import { InternalLink } from '@/components/InternalLink';
import { MainContent } from '@/components/MainContent';
import { OrderToSignAlert } from '@/components/OrderToSignAlert/OrderToSignAlert';
import { PageHeading } from '@/components/PageHeading';
import { useTexts } from '@/hooks/useTexts';

import { AllConnectFunds } from './AllConnectFunds';
import { FundsWithHoldings } from './FundsWithHoldings';

export const FundListPage: React.FC = () => {
  const organizationCmId = useOrganizationCmId();
  const texts = useTexts();
  const fundListQuery = useFundListQuery();
  const holdingsQuery = useFetchFundHoldingsQuery();

  if (isNotReady(fundListQuery)) {
    return <QueryState query={fundListQuery} />;
  }

  if (
    isNotReady(holdingsQuery, {
      // Error will be handled by the consuming component.
      skipError: true,
    })
  ) {
    return <QueryState query={holdingsQuery} />;
  }

  const availableFunds = fundListQuery.data.funds.filter(
    (fund) => !fund.closedForSubscription,
  );

  return (
    <CenteredPageContent data-test="fund-list-page">
      <AnnouncementWarning />
      <OrderToSignAlert />

      <Breadcrumbs>
        <InternalLink to={`/${organizationCmId}`}>
          {texts.menu.links.home}
        </InternalLink>

        <InternalLink to={`/${organizationCmId}/fund`}>
          {texts.menu.links.fundInformation}
        </InternalLink>

        <InternalLink to={`/${organizationCmId}/fund/fund-list`}>
          {texts.menu.links.fundList}
        </InternalLink>
      </Breadcrumbs>

      <MainContent>
        <PageHeading header={texts.menu.links.fundList} />

        <FundsWithHoldings
          allFunds={fundListQuery.data.funds}
          holdings={holdingsQuery.data}
          isHoldingsQueryError={holdingsQuery.isError}
        />

        <AllConnectFunds funds={availableFunds} />
      </MainContent>
    </CenteredPageContent>
  );
};
