/**
 * Get the maximum number of fraction digits from a list of numbers.
 *
 * Trailing zeros are removed from the fraction part of the number before counting the fraction digits.
 *
 * @param numbers List of numbers to get the maximum number of fraction digits from.
 *
 * @returns The maximum number of fraction digits from the list of numbers.
 *
 * @example
 * numbers = [1.23, 4.567, 89.1234, 0.1, 0.123400]
 *
 * Will return 4, because the number with the most fraction digits is 89.1234 with 4 fraction digits.
 *
 * The number 0.123400 has 6 fraction digits, but since the trailing zeros are removed the number has only 4 fraction digits.
 */
export function getMaxFractionDigits(numbers: number[]): number {
  let maxFractionDigits = 0;

  numbers.forEach((number) => {
    const fractionPart = number.toString().split('.')[1] || '';
    const trimmedFractionPart = fractionPart.replace(/0+$/, ''); // Remove trailing zeros.
    const fractionDigits = trimmedFractionPart.length;

    if (fractionDigits > maxFractionDigits) {
      maxFractionDigits = fractionDigits;
    }
  });

  return maxFractionDigits;
}
