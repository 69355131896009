export const media = {
  mobile: 'only screen and (max-width: 767px)',
  tabletAndAbove: 'only screen and (min-width: 768px)',
  tabletAndBelow: 'only screen and (max-width: 767px)',
  desktop: 'only screen and (min-width: 992px)',
  maxContentWidthAndBelow: 'only screen and (max-width: 1140px)',
  ie: 'all and (-ms-high-contrast: none), (-ms-high-contrast: active)',
};

const padBase = 8;
export const padding = {
  base: padBase,
  medium: padBase * 2,
  large: padBase * 3,
  xl: padBase * 4,
  xxl: padBase * 5,
  xxxl: padBase * 6,
  xxxxl: padBase * 7,
};
