import { startOfDay } from 'date-fns';

import { datePickerDateFormat } from '@/util/datePickerDateFormat';

const today = startOfDay(new Date());

export const expectedTransferDate = {
  /**
   * The earliest date the transfer can be done.
   */
  minimumDate: (): Date => today,

  /**
   * The date format to use in the date picker,
   * validation schema and in the UI.
   */
  dateFormat: datePickerDateFormat,
};
