/**
 * The date format to use in the date picker,
 * validation schema and in the UI.
 */
export function datePickerDateFormat(dateFnsLocale: Locale): string {
  // The i18n date formats in date-fns are sometimes
  // using the short hand format for the year.
  // E.g. 'dd.MM.y' instead of 'dd.MM.yyyy'.
  // We display this value to the user and to avoid
  // confusion we override the date format to make
  // more sense when read by a user.
  switch (dateFnsLocale.code) {
    // The Norwegian locale are using the short hand
    // format for the year.
    // https://github.com/date-fns/date-fns/blob/main/src/locale/nb/_lib/formatLong/index.ts
    case 'nb':
    case 'nn':
      return 'dd.MM.yyyy';

    // The Swedish locale is using the short hand
    // format for the year.
    // https://github.com/date-fns/date-fns/blob/main/src/locale/sv/_lib/formatLong/index.ts
    case 'sv':
      return 'yyyy-MM-dd';

    // The en-GB and en-US locale are using the long hand
    // format for the year. Thus we can get the format
    // from the date-fns locale object.
    // https://github.com/date-fns/date-fns/blob/main/src/locale/en-GB/_lib/formatLong/index.ts
    default:
      return dateFnsLocale.formatLong?.date({ width: 'short' });
  }
}
