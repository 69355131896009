import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';

import { CrossroadsReportTypeDto } from '@/api/reports/dtos/CrossroadsReportTypeDto';
import { useIHubOrganizationReportsQuery } from '@/api/reports/reportsApi';

import { Report, ReportSource } from '../types/ReportModel';
import { ReportsFilter } from '../types/ReportsFilter';
import { filterByReportSource } from '../utils/filterByReportSource';
import { isIhubCostReport } from '../utils/isIhubCostReport';
import { mapIHubReportToReport } from '../utils/mapIHubReportToReport';
import { mapReportFileToReport } from '../utils/mapReportFileToReport';
import { useWolfReports } from './useWolfReports';

interface Reports {
  reports: Report[];
  reportsIsPending?: boolean;
  reportsIsError?: boolean;
}

const defaultData = [];

export const useReports = (filter: ReportsFilter): Reports => {
  const { wolfReports, wolfReportsIsSuccess, wolfReportsIsError } =
    useWolfReports(filter);
  const organizationReportsQuery = useIHubOrganizationReportsQuery(filter);

  const isError = wolfReportsIsError || organizationReportsQuery.isError;
  const isFetching =
    !wolfReportsIsSuccess || !organizationReportsQuery.isSuccess;

  // Map reports to view models.
  return useMemo<Reports>(() => {
    if (isError) {
      return {
        reports: defaultData,
        reportsIsError: true,
      };
    }

    // Wait for both data sources to finish fetching before processing them.
    // Otherwise one data set will be visible and then after some time the
    // other data set will pop in.
    if (isFetching) {
      return {
        reports: defaultData,
        reportsIsPending: true,
      };
    }

    const ihubReportViewModels = organizationReportsQuery.data?.map((report) =>
      mapIHubReportToReport(report, ReportSource.IHubOrganization),
    );

    // :: Check if the organization has a cost report from iHub.
    // If the organization has a cost report from iHub, we should not show
    // the yearly cost report from Wolf.
    const hasIHubCostReport = ihubReportViewModels.some((report) =>
      isIhubCostReport(report.name),
    );

    // Filter out yearly cost report from Wolf if the organization has a cost report from iHub.
    let filteredWolfReports = wolfReports;
    if (hasIHubCostReport) {
      filteredWolfReports = wolfReports.filter(
        (report) =>
          // Remove yearly cost report from the list.
          report.reportType !== CrossroadsReportTypeDto.YearlyCostReport,
      );
    }

    const wolfReportViewModels = filteredWolfReports.map(mapReportFileToReport);

    const filteredReports = filterByReportSource(
      [...wolfReportViewModels, ...ihubReportViewModels],
      filter.reportSource,
    );

    const reports = orderBy(filteredReports, (report) => report.reportDateISO, [
      'desc',
    ]);

    return {
      reports,
    };
  }, [wolfReports, organizationReportsQuery.data, isFetching, isError]);
};
