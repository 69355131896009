import { createApi } from '@reduxjs/toolkit/query/react';
import { format } from 'date-fns';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';
import { FundListDto } from './dtos/FundListDto';
import { FundNavListDto } from './dtos/FundNavListDto';
import { FundList } from './models/FundList';
import { FundNavList } from './models/FundNavList';
import { transformFundListResponse } from './utils/transformFundListResponse';
import { transformFundNavListResponse } from './utils/transformFundNavListResponse';

export const fundsApi = createApi({
  reducerPath: 'fundsApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    fundList: builder.query<FundList, void>({
      query: () => ({
        url: `${window.config.connectBaseApiUrl}/api/funds`,
      }),
      transformResponse: (dto: FundListDto) => {
        return transformFundListResponse(dto);
      },
    }),

    /**
     * Fetches the latest NAVs for all Connect funds on or before the specified date.
     */
    fundNavList: builder.query<FundNavList, { navOnOrBeforeDate: Date }>({
      query: ({ navOnOrBeforeDate }) => ({
        url: `${window.config.connectBaseApiUrl}/api/funds/latestNavs?onOrBeforeDate=${format(navOnOrBeforeDate, 'yyyy-MM-dd')}`,
      }),
      transformResponse: (dto: FundNavListDto) => {
        return transformFundNavListResponse(dto);
      },
    }),
  }),
});

export const { useFundListQuery, useFundNavListQuery } = fundsApi;
