import Fuse from 'fuse.js';

import { Nav } from '@/api/funds/models/Nav';
import { nameof } from '@/util/nameof';

export const fuzzySearchFundNav = (query: string, nav: Nav[]): Nav[] => {
  if (query === '') {
    return nav;
  }

  const fuse = new Fuse(nav, {
    keys: [
      nameof<Nav>('fundName'),
      nameof<Nav>('isin'),
      nameof<Nav>('currency'),
    ],

    // Make the most relevant result be on top.
    shouldSort: true,

    // Make the search more restrictive as not to show results that is not
    // relevant.
    threshold: 0.3,
  });

  // Use fuzzy search.
  return fuse.search(query).map((result) => result.item);
};
