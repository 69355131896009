import Table, { IColumn } from '@storeblocks/table';
import { format } from 'date-fns';
import React from 'react';
import styled from 'styled-components';

import { Nav } from '@/api/funds/models/Nav';
import { useTexts } from '@/hooks/useTexts';
import { useUserDateFormat, useUserLocale } from '@/i18n/hooks';
import { formatNumber } from '@/util/formatNumber';
import { nameof } from '@/util/nameof';

interface Props {
  nav: Nav[];

  /**
   * The number of decimal places to display in the NAV values.
   */
  navValuePrecision: number;
}

interface TableData {
  id: string;
  fundName: string;
  isin: string;
  nav: string;
  navDate: string;
  navDateUnformatted: Date;
  navUnformatted: number;
}

export const NavList: React.FC<Props> = ({ nav, navValuePrecision }) => {
  const dateFormat = useUserDateFormat();
  const texts = useTexts();
  const locale = useUserLocale();

  const columns: IColumn[] = [
    {
      name: texts.pages.fundNavList.table.name,
      key: nameof<Nav>('fundName'),
      dataType: 'text',
    },
    { name: texts.pages.fundNavList.table.isin, key: 'isin', dataType: 'text' },
    {
      name: texts.pages.fundNavList.table.date,
      key: nameof<Nav>('navDate'),
      dataType: 'text',
    },
    {
      name: texts.pages.fundNavList.table.nav,
      key: nameof<Nav>('nav'),
      dataType: 'text',
      style: { textAlign: 'right', whiteSpace: 'nowrap' },
    },
  ];

  const tableData: TableData[] = nav.map((item) => ({
    id: `${item.isin}-${item.currency}`,

    // Properties that are displayed in the table.
    // These must match the key property of the columns.
    fundName: item.fundName,
    isin: item.isin,

    nav: item.hasNav
      ? // When NAV is available, display the NAV.
        formatNumber(item.nav, locale, item.currency, {
          minimumFractionDigits: navValuePrecision,
          maximumFractionDigits: navValuePrecision,
        })
      : // When NAV is not available, display a message that
        // the NAV is not available for the selected date.
        texts.pages.fundNavList.table.noNavAvailable,

    navDate: item.hasNav ? format(item.navDate, dateFormat) : '',

    // Properties that are only used for sorting.
    navDateUnformatted: item.hasNav ? item.navDate : new Date(0),
    navUnformatted: item.hasNav ? item.nav : 0,
  }));

  return (
    <StyledTable
      columns={columns}
      data={tableData}
      sortable={true}
      // The initial sort column and direction.
      sortColumn={nameof<Nav>('fundName')}
      sortDirection="asc"
      // Custom sort function that sorts the data based on the column key.
      // @ts-expect-error The types are wrong in the Table component.
      customSortFunction={(data: TableData[], sortColumn, sortDirection) => {
        let sorted: TableData[];

        switch (sortColumn.key) {
          case nameof<Nav>('fundName'):
            sorted = data.sort((a, b) => a.fundName.localeCompare(b.fundName));
            break;

          case nameof<Nav>('isin'):
            sorted = data.sort((a, b) => a.isin.localeCompare(b.isin));
            break;

          case nameof<Nav>('navDate'):
            sorted = data.sort(
              (a, b) =>
                new Date(a.navDateUnformatted).getTime() -
                new Date(b.navDateUnformatted).getTime(),
            );
            break;

          case nameof<Nav>('nav'):
            sorted = data.sort((a, b) => a.navUnformatted - b.navUnformatted);
            break;

          default:
            sorted = data;
            break;
        }

        return sortDirection === 'asc' ? sorted : sorted.reverse();
      }}
    />
  );
};

const StyledTable = styled(Table)`
  & table {
    width: initial;
  }

  & tr th,
  & tr td {
    width: initial;
  }

  & tr th:nth-of-type(1),
  & tr td:nth-of-type(1) {
    width: 100%;
    line-height: 24px;
  }
`;
