import Fuse from 'fuse.js';

import { ReportFile } from '@/api/reports/models/ReportFile';

export const filterByName = (
  reports: ReportFile[],
  name?: string,
): ReportFile[] => {
  if (!name) {
    return reports;
  }

  const fuse = new Fuse(reports, {
    keys: ['name'],

    // Make the most relevant result be on top.
    shouldSort: true,

    // Make the search more restrictive as not to show results that is not
    // relevant.
    threshold: 0.3,
  });

  // Use fuzzy search.
  return fuse.search(name).map((result) => result.item);
};
