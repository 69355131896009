import Button from '@storeblocks/button';
import { Illustration } from '@storeblocks/icon';
import LinkList from '@storeblocks/link-list';
import { toString } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import { useFetchFundHoldingsQuery } from '@/api/fundHoldings/fundHoldingsApi';
import { useFundListQuery } from '@/api/funds/fundsApi';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { InternalLink } from '@/components/InternalLink';
import { H3, Paragraph, TextShortBold } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { toUrlFriendlySlug } from '@/pages/FundList/utils/toUrlFriendlySlug';
import { TrackingElement, useGetTrackId } from '@/tracking';

interface Props {
  portfolioGroupId?: string;
}

/**
 * Display a list of all the funds in the given portfolio group.
 */
export const EsgFundList: React.FC<Props> = ({ portfolioGroupId }) => {
  const texts = useTexts();
  const organizationCmId = useOrganizationCmId();
  const fundListQuery = useFundListQuery();
  const holdingsQuery = useFetchFundHoldingsQuery();
  const getTrackId = useGetTrackId();

  const holdingsForPortfolioGroup = holdingsQuery.data.all
    .filter((holdings) =>
      // If portfolioGroupId is not given, return all holdings.
      portfolioGroupId ? holdings.portfolioGroupId === portfolioGroupId : true,
    )
    // Make the funds with the highest market value appear first.
    .sort((holdings) => holdings.marketValue) // Ascending sort.
    .reverse() // Make it descending.
    .map((holding) => holding.isin);

  const fundsForPortfolioGroup = fundListQuery.data.funds.filter((fund) =>
    holdingsForPortfolioGroup.includes(fund.isin),
  );

  const firstFiveFunds = fundsForPortfolioGroup.slice(0, 5);

  if (fundsForPortfolioGroup.length === 0) {
    return (
      <MinHeightWithGap>
        <H3>{texts.pages.esg.dataOnFundLevel.noFunds.title}</H3>

        <div>
          <Button
            to={`/${organizationCmId}/fund/fund-list`}
            as={InternalLink}
            variant="text"
            iconRight="arrow-right"
            data-trackid={getTrackId(
              'go-to-fund-list-ad',
              TrackingElement.Link,
            )}
            data-trackid-source="esg-page"
          >
            {texts.pages.esg.dataOnFundLevel.noFunds.linkText}
          </Button>
        </div>

        <StyledIllustration name="sustainable-city" />
      </MinHeightWithGap>
    );
  }

  return (
    <MinHeightWithGap>
      <WithGap gap="8">
        <H3>{texts.pages.esg.dataOnFundLevel.title}</H3>

        <Paragraph>
          {fmsWithTemplate(texts.pages.esg.dataOnFundLevel.widgetDescription, {
            actualFunds: toString(firstFiveFunds.length),
            fundsLength: toString(fundsForPortfolioGroup.length),
          })}
        </Paragraph>
      </WithGap>

      <LinkList>
        {firstFiveFunds.map((fund) => (
          <InternalLink
            key={fund.isin}
            to={`/${organizationCmId}/sustainability/esg/fund/${
              fund.isin
            }/${toUrlFriendlySlug(fund.name)}`}
            data-trackid={getTrackId('go-to-fund', TrackingElement.Link)}
            data-trackid-source="esg-page"
            data-trackid-isin={fund.isin}
            data-trackid-fund-name={fund.name}
          >
            {fund.name}
          </InternalLink>
        ))}
      </LinkList>

      {fundsForPortfolioGroup.length > 5 && (
        <TextShortBold>
          {fmsWithTemplate(texts.pages.esg.dataOnFundLevel.thereAreMoreFunds, {
            amount: (fundsForPortfolioGroup.length - 5).toString(),
          })}
        </TextShortBold>
      )}

      <AlignBottom>
        <Button
          to={`/${organizationCmId}/fund/fund-list`}
          as={InternalLink}
          variant="text"
          iconRight="arrow-right"
          data-trackid={getTrackId('go-to-fund-list', TrackingElement.Link)}
          data-trackid-source="esg-page"
        >
          {texts.pages.esg.dataOnFundLevel.seeAllFunds}
        </Button>
      </AlignBottom>
    </MinHeightWithGap>
  );
};

const MinHeightWithGap = styled(WithGap)`
  background-color: var(--color-green-10);
  min-height: 500px;
  padding: 24px;
`;

const AlignBottom = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
`;

const StyledIllustration = styled(Illustration)`
  margin-top: auto;

  svg {
    min-width: 400px;
  }
`;
