import mime from 'mime';

import { convertStringToEnum } from '@/util/convertStringToEnum';
import { getFileExtension } from '@/util/FileHelpers';

import { CrossroadsReportsReply } from '../dtos/CrossroadsReportsReply';
import { CrossroadsReportTypeDto } from '../dtos/CrossroadsReportTypeDto';
import { ReportEndpoint } from '../models/ReportEndpoint';
import { ReportFile } from '../models/ReportFile';

export const transformCrossroadsReportsReply = (
  reply: CrossroadsReportsReply,
): Array<ReportFile> =>
  reply.documents.map((document) => ({
    name: `${document.displayName}.${getFileExtension(document.filename)}`,
    id: document.uuid,
    size: -1,
    fileType: mime.getExtension(document.mimeType) ?? 'unknown',
    // Removing timezone to make sure we have the same date everywhere
    /** @todo: check me */
    reportDateISO: document.createdDate.split('T')[0],
    endpoint: ReportEndpoint.Crossroads,
    reportType: convertStringToEnum(
      document.reportType.toUpperCase(),
      CrossroadsReportTypeDto,
    ),
  }));
