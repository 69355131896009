import Button from '@storeblocks/button';
import React, { FC } from 'react';
import styled from 'styled-components';

import { NewsArticle } from '@/api/newsArticles/models/newsArticlesModels';
import { useTexts } from '@/hooks/useTexts';
import { TrackingElement, useGetTrackId } from '@/tracking';

interface Props {
  newsArticle: NewsArticle;
}

/**
 * Card component for displaying a news article.
 * This component mimics the rebrand card component and should be
 * replaced with the rebrand card component when it is available.
 *
 * The card is designed to handle multi line headers and descriptions.
 * Since the Swedish news articles have longer headers and descriptions.
 */
export const NewsArticleCard: FC<Props> = ({ newsArticle }) => {
  const texts = useTexts();
  const getTrackId = useGetTrackId();

  return (
    <Card>
      <ImageContainer>
        <Image
          src={newsArticle.image}
          // The image are essentially illustration images and should
          // be hidden from the screen readers by setting the alt text to empty.
          alt=""
        />
      </ImageContainer>

      <Content>
        <H1>{newsArticle.header}</H1>

        {newsArticle.description.map((paragraph, index) => (
          <Description key={index}>{paragraph}</Description>
        ))}

        <ButtonContainer>
          <Button
            as="a"
            href={newsArticle.link}
            target="_BLANK"
            data-trackid={getTrackId('go-to-article', TrackingElement.Link)}
            data-trackid-article-title={newsArticle.header}
            variant="text"
            iconRight="arrow-right"
          >
            {texts.news.articles.readArticleLink}
          </Button>
        </ButtonContainer>
      </Content>
    </Card>
  );
};

const Card = styled.div`
  display: flex;
  align-items: stretch;
  background-color: var(--color-surface);
  border-radius: 16px;
`;

const ImageContainer = styled.div`
  margin: 16px;
`;

const Image = styled.img`
  object-fit: fill;
  width: 404px;
  max-height: 268px;
  border-radius: 16px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 24px 24px 16px;
  flex: 1;
`;

const H1 = styled.h1`
  margin: 0;
  font-family: 'Storebrand Display', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: var(--color-on-background);
`;

const Description = styled.p`
  margin: 0;
`;

const ButtonContainer = styled.div`
  margin-top: auto;

  // Align the button to the news text.
  margin-left: -24px;
`;
