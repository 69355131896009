import { CSSObject, Options } from 'highcharts';
import round from 'lodash/round';

import { useUserLocale } from '@/i18n/hooks';

import { getDefaultGaugeOptions } from './getDefaultGaugeOptions';

export const useGaugeOptions = (percent: number, size: number): Options => {
  const locale = useUserLocale();

  const defaultOptions = getDefaultGaugeOptions(size);

  return {
    ...defaultOptions,
    series: [
      {
        type: 'solidgauge',
        data: [
          {
            color: 'var(--color-green-10)',
            y: 100,
          },
          {
            color: 'var(--color-green-50)',
            y: percent,
          },
        ],
        dataLabels: {
          borderWidth: 0,
          style: getLabelStyle(),
          formatter() {
            return `${round(percent, 2).toLocaleString(locale)}%`;
          },
        },
      },
    ],
    pane: {
      ...defaultOptions.pane,
    },
    yAxis: {
      ...defaultOptions.yAxis,
      min: 0,
      max: 100,
    },
  };
};

const getLabelStyle = (): CSSObject => ({
  fontFamily: 'Open Sans, sans-serif',
  fontWeight: '600',
  fontSize: '32px',
});
