/**
 * Check if the report is a cost report from iHub.
 *
 * The only thing that will indicate that a report is a cost report from iHub is the name of the report.
 *
 * The cost report from iHub has a Norwegian name and an English name.
 *
 * @param reportName The name of the report from iHub.
 *
 * @returns Whether the report is a cost report from iHub.
 */
export const isIhubCostReport = (reportName: string): boolean => {
  // This regex matches strings that contain either "Cost report" or "Kostnadsrapport"
  const ihubCostReportRegex = /Cost report|Kostnadsrapport/;

  return ihubCostReportRegex.test(reportName);
};
