import Breadcrumbs from '@storeblocks/breadcrumbs';
import React, { FC } from 'react';

import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { InternalLink } from '@/components/InternalLink';
import { InternalLinkList } from '@/components/InternalLinkList';
import { MainContent } from '@/components/MainContent';
import { PageHeading } from '@/components/PageHeading';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';

export const IntermediateFundListPage: FC = () => {
  const texts = useTexts();
  const organizationCmId = useOrganizationCmId();

  const links = [
    <InternalLink
      key={`/${organizationCmId}/fund/fund-list`}
      to={`/${organizationCmId}/fund/fund-list`}
      data-test="fund-list-link"
    >
      {texts.menu.links.fundList}
    </InternalLink>,

    <InternalLink
      key={`/${organizationCmId}/fund/nav`}
      to={`/${organizationCmId}/fund/nav`}
      data-test="fund-nav-link"
    >
      {texts.menu.links.navList}
    </InternalLink>,
  ];

  return (
    <WithGap>
      <Breadcrumbs>
        <InternalLink to={`/${organizationCmId}`}>
          {texts.menu.links.home}
        </InternalLink>

        <InternalLink to={`/${organizationCmId}/fund`}>
          {texts.menu.links.fundInformation}
        </InternalLink>
      </Breadcrumbs>

      <MainContent>
        <PageHeading header={texts.menu.links.fundInformation} />

        <InternalLinkList links={links} />
      </MainContent>
    </WithGap>
  );
};
